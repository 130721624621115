body {
  margin: 0 !important;
  padding-right: 0 !important;
  //font-size: 14.4px !important;
  overflow: auto !important; //to prevent glicth(movement from left to right or vice versa) because of the absence of scroll
  overflow-y: scroll !important; //to prevent glicth because of absence of scroll
}

// Quick Styling
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1100;
}

.loader {
  margin: 0 auto;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #1782c5;
  border-color: #1782c5 transparent #1782c5 transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media print {
  @page {
    margin: 10mm 0;
  }
}